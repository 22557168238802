body {
  background: #202020;
  color: #efefef;
  width: 100%;
  height: 100%;
}

div {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

.padding {
  padding: 1rem;
  width: 100%;
  height: 100%;
}

p {
  font-family: monospace;
  width: 50em;
  max-width: 100%;
}

a {
  &:link{
    color: #8cb4ff;
  }
  &:visited {
    color: #ffadff;
  }
}

.preview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  column-gap: 10px;
  row-gap: 10px;
  align-items: center;
  padding: 15px;
  width: 100%;
  max-width: 50rem;
}
